import React from "react";

const PageNotFound = () => {
  return (
    <section className="grid">
      <h2>Page not found.</h2>
    </section>
  );
};

export { PageNotFound };
