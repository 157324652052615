import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "@react-spring/web";

import {
  RequestOTPParams,
  SubmitOTPParams,
  useIsSignedInMutation
} from "@app/utils/query";
import { Button, Logo } from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { Footer, Splash } from "../_Shared";

enum LoginStep {
  ENTER_EMAIL,
  ENTER_OTP
}

export const BetaSignup = () => {
  return (
    <Splash as="main">
      <form
        className="auth auth--wider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="_flex _flex-center _flex-spaceBetween">
          <Logo size="sm" />
          <h1 className="auth_badge">Beta Signup</h1>
        </div>

        <h2 className="auth_h2 ">Welcome to ShipThis Beta Signup.</h2>

        <p className="auth_p _mt0">
          Shipthis is a service to get your Godot games to the app store
          quickly.
          <br />
          <br />
          We’ve build the basics but we will need to test it and improve it
          before launching fully. <br />
          <br />
          Have a look at what you need to get started{" "}
          <a href="/docs/quickstart#what-youll-need">here</a>
          .
          <br />
          <br />
          We can offer you this in exchange for telling us what you think about
          it.
          <br />
        </p>

        <ul className="auth_features">
          <li>Lifetime membership</li>
          <li>Free support for the duration of the beta</li>
        </ul>

        <Button size="md" href="/login" centeredLoader>
          Continue
        </Button>
        <input type="submit" hidden />
      </form>
      <Footer className="auth_footer--wider" />
    </Splash>
  );
};
