import React from "react";
import { NavLink } from "react-router-dom";
import { SquareArrowRightUp } from "@app/components/Icons";

import helloInvent from "@assets/images/helloInvent.png";

const Footer = () => (
  <footer className="footer">
    <div className="footer-company">
      <a href="//helloinvent.com" className="footer-logo">
        <img
          src={helloInvent}
          width="142.31"
          height={54.95}
          alt="Hello Invent Logo"
        />
      </a>
      <div className="footer-copy">
        Hello Invent © 2024
        <br />
        All rights reserved
      </div>
    </div>
    <div className="footerSections">
      <ul className="footerSection">
        <li className="footerSection-header">Main</li>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <a href="/docs">Docs</a>
        </li>
        <li>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </li>
      </ul>

      <ul className="footerSection">
        <li className="footerSection-header">Docs</li>
        <li>
          <a href="/docs/intro">Intro</a>
        </li>
        <li>
          <a href="/docs/quickstart">QuickStart</a>
        </li>
        <li>
          <a href="/docs/category/tutorial">Tutorial</a>
        </li>
        <li>
          <a href="/docs/reference">Reference</a>
        </li>
      </ul>
      <ul className="footerSection">
        <li className="footerSection-header">More</li>
        <li className="_flex">
          <NavLink to="https://www.npmjs.com/package/shipthis" target="_blank">
            NPM package
            <SquareArrowRightUp
              width={18}
              height={18}
              className="footer-externalLink"
            />
          </NavLink>
        </li>
        <li className="_flex">
          <NavLink to="https://discord.gg/gPjn3S99k4" target="_blank">
            Discord
            <SquareArrowRightUp
              width={18}
              height={18}
              className="footer-externalLink"
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="/terms">Terms & Conditions</NavLink>
        </li>
        <li>
          <NavLink to="/privacy">Privacy</NavLink>
        </li>
      </ul>
    </div>
  </footer>
);

export { Footer };
