import React from "react";
import { animated } from "@react-spring/web";
import { Message } from "@app/components/Icons";

interface Props extends React.ComponentProps<"a"> {
  style: any;
}

const OpenButton = ({ style, onClick }: Props) => (
  <animated.a
    href="#"
    className="feedback-openButton"
    onClick={onClick}
    style={style}
    aria-label="Open feedback widget"
  >
    <Message />
  </animated.a>
);

export { OpenButton };
