import axios from "axios";
import { useState } from "react";

import { getAuthHeaders } from "@app/utils/query";
import { API_URL } from "@app/constants";

interface DownloadCredentialParams {
  credentialId: string;
  // Only included if the credential is associated with a project
  projectId?: string;
}

export function useDownloadCredential() {
  const [isLoading, setIsLoading] = useState(false);

  const downloadCredential = async ({
    credentialId,
    projectId
  }: DownloadCredentialParams) => {
    try {
      // Trigger the export to s3 - it will return a url
      setIsLoading(true);
      const headers = getAuthHeaders();

      const url = projectId
        ? `${API_URL}/projects/${projectId}/credentials/${credentialId}/export`
        : `${API_URL}/credentials/${credentialId}/export`;

      const { data } = await axios.post(
        url,
        {}, // no-body
        { headers }
      );
      // Redirect to the downloadCredential URL
      const downloadCredentialUrl = data.url;
      window.open(downloadCredentialUrl, "_blank");
    } catch (error) {
      console.warn("downloadCredential Error", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadCredential, isLoading };
}
