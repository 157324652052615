import React from "react";
import { X } from "@app/components/Icons";

interface Props extends React.ComponentProps<"section"> {}

const CloseButton = ({ onClick }: Props) => (
  <a
    href="#"
    className="feedback-closeButton"
    onClick={onClick}
    aria-label="Close feedback widget"
  >
    <X />
  </a>
);

export { CloseButton };
