import classNames from "classnames";
import useMeasure from "react-use-measure";
import { useEffect, useRef, useState } from "react";
import { Platform, Project } from "@app/types";
import { useProjectProgress } from "@app/utils/query";
import { Section, GraphUp, Check, Toggle } from "@app/components";
import { animated, useSpring } from "@react-spring/web";

const keyToLabel = {
  hasApiKeyForPlatform: "Api Key",
  hasBundleSet: "Bundle",
  hasCredentialsForPlatform: "Credentials"
};

const useGetCurrentStage = (data) => {
  const [currentStage, setCurrentState] = useState(1);

  useEffect(() => {
    if (data?.hasCredentialsForPlatform) {
      setCurrentState(4); // DONE
    } else if (data?.hasBundleSet) {
      setCurrentState(3);
    } else if (data?.hasApiKeyForPlatform) {
      setCurrentState(2);
    } else {
      setCurrentState(1);
    }
  }, [data]);

  return currentStage;
};

const GameSetupProgress = ({ projectId }: { projectId: Project["id"] }) => {
  const [platform, setPlatform] = useState<Platform>(Platform.IOS);
  const { data, isLoading } = useProjectProgress(projectId, platform);
  const currentStage = useGetCurrentStage(data);
  const [adviceRef, { height }] = useMeasure();

  const [adviceStyle] = useSpring(
    () => ({
      height: height
    }),
    [height]
  );

  return (
    <Section>
      <div className="_mb10">
        <div className="gameOverview-header">
          <div className="_flex _flex-center">
            <GraphUp
              width={18}
              height={18}
              className="gameOverview-headerIcon"
            />
            <div>Setup Progress</div>
          </div>
          <Toggle
            value={platform}
            values={[Platform.IOS, Platform.ANDROID]}
            onChange={(value) => setPlatform(value)}
          />
        </div>
      </div>

      <div className="gameOverview-setup">
        {[
          "hasApiKeyForPlatform",
          "hasBundleSet",
          "hasCredentialsForPlatform"
        ].map((key, i) => {
          const isSetup = data?.[key];
          const index = i + 1;
          return (
            <div
              key={i}
              className={classNames("gameOverview-setupItem", {
                "gameOverview-setupItem--done": isSetup,
                "gameOverview-setupItem--current": currentStage - 1 === i
              })}
            >
              <div className="gameOverview-setupItem--index">{index}</div>
              <div className="gameOverview-setupItem--name">
                {keyToLabel?.[key]}
              </div>
              {isSetup && (
                <div className="gameOverview-setupItem--check">
                  <Check width={12} height={12} strokeWidth={3} />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <animated.div
        className="gameOverview-setupItem--advice"
        style={adviceStyle}
      >
        <div ref={adviceRef} className="gameOverview-setupItem--adviceContent">
          {!isLoading && currentStage === 1 && (
            <div>
              To set up your api key..., consectetur adipiscing elit.
              Pellentesque elementum dignissim ultricies. Fusce rhoncus ipsum
              tempor eros aliquam consequat. Lorem ipsum dolor sit amet
            </div>
          )}
          {!isLoading && currentStage === 2 && (
            <div>
              To hook up the bundle..., consectetur adipiscing elit.
              Pellentesque elementum dignissim ultricies.
            </div>
          )}
          {!isLoading && currentStage === 3 && (
            <div>
              Credentials setup..., consectetur adipiscing consectetur
              adipiscing elit. Pellentesque elementum dignissim ultricies.
            </div>
          )}
          {!isLoading && currentStage === 4 && (
            <div>{platform} Setup is done!</div>
          )}
        </div>
      </animated.div>
    </Section>
  );
};

export { GameSetupProgress };
