import React, { useState } from "react";
import { Platform } from "@app/types";
import { getErrorMessage } from "@app/utils/errors";
import { useUserCredentials } from "@app/utils/query";
import { useCopyToClipboard, useDownloadCredential } from "@app/utils/hooks";
import { Box, Button, Section, Toggle } from "@app/components";

import { Header } from "../_Shared";
import { CredentialList } from "./CredentialList";

import { Copy } from "@app/components";
interface CliCommandProps {
  command: string;
  isHelpLink?: boolean;
  isCopyable?: boolean;
  helpLink?: "command" | "topic"; // if we link to the command help doc or the parent topic doc
}

const CliCommand = ({
  command,
  isHelpLink = true,
  isCopyable = true,
  helpLink = "command",
}: CliCommandProps) => {
  const { copyText, isCopying } = useCopyToClipboard();

  const [commandName, ...subCommand] = command.split(" ");
  const helpParts = helpLink === "topic" ? subCommand.slice(0, -1) : subCommand;
  const helpPath = `/docs/reference/${helpParts.join("/")}`;

  return (
    <div className="cli-command">
      {isHelpLink ? (
        <a href={helpPath} target="_blank">
          <code>{command}</code>
        </a>
      ) : (
        <code>{command}</code>
      )}
      {isCopyable && (
        <Button
          isLoading={isCopying}
          variant="secondary"
          onClick={() => copyText(command)}
          className="cli-command__copy"
        >
          <Copy width={14} />
        </Button>
      )}
    </div>
  );
};

const Credentials = () => {
  const { data, isLoading, isSuccess } = useUserCredentials();
  const [platform, setPlatform] = useState<Platform>(Platform.IOS);

  const missing =
    platform == Platform.IOS ? (
      <div>
        <h4>First Time Setup</h4>
        <br />
        Run the following command to create your first game and generate all
        necessary credentials:
        <CliCommand command="shipthis game wizard" />
        <h4>Manual Setup Options</h4>
        <br />
        Create an App Store Connect API Key:
        <CliCommand command="shipthis apple apiKey create" helpLink="topic" />
        Create a Distribution Certificate by running
        <CliCommand
          command="shipthis apple certificate create"
          helpLink="topic"
        />
      </div>
    ) : (
      <div>
        <h4>First Time Setup</h4>
        <br />
        Run the following command to create your first game and generate all
        necessary credentials:
        <CliCommand command="shipthis game wizard" />
      </div>
    );

  return (
    <main className="admin-main">
      <Header title="Credentials" />

      {/* <div className="grid grid--2col admin__grid"> */}
      <Section>
        <div className="_mb10">
          <div className="gameOverview-header">
            <div className="_flex _flex-center">
              <Box width={18} height={18} className="gameOverview-headerIcon" />
              <div>Keys and Certificates</div>
            </div>
            <Toggle
              value={platform}
              values={[Platform.IOS, Platform.ANDROID]}
              onChange={(value) => setPlatform(value)}
            />
          </div>
          <div className="font--sm _mb15">
            These credentials are global to all of your games.
          </div>
        </div>

        <CredentialList
          isLoading={isLoading}
          isSuccess={isSuccess}
          platform={platform}
          missingMessage={missing}
          credentials={data?.userCredentials.filter(
            (c) => c.platform === platform
          )}
        />
      </Section>
      {/* </div> */}
    </main>
  );
};

export { Credentials };
