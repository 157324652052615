import React, { useState } from "react";
import GitInfo from "react-git-info/macro";
import { DateTime } from "luxon";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { Logo } from "@app/components";
import { useIsMobile, useLockBody } from "@app/utils/hooks";
import { Dashboard, FileKey, Gamepad, Git } from "@app/components/Icons";
import { hideSidebar, useSidebarState } from "@app/utils/emitters";

import { Overlay } from "./Overlay";

const LastCommit = () => {
  const gitInfo = GitInfo();
  const commitDate = DateTime.fromISO(gitInfo.commit.date);
  const formattedDate = DateTime.fromISO(commitDate).toRelative();
  return (
    <div className="commit">
      <Git className="commit-icon" />
      <div>
        #{gitInfo.commit.shortHash}
        <br />
        {formattedDate}
      </div>
    </div>
  );
};

const ServerActivity = () => {
  const [isActive, setIsActive] = useState(true);
  const loc = { x: 12, y: 12 };
  const duration = 8;
  const fromFillColor = isActive ? "#485b6c" : "#6D3131";
  const circleRadius = isActive ? 3 : 4;

  const Animation = () => (
    <svg width={2 * loc.x} height={2 * loc.y} style={{ marginLeft: 0 }}>
      <circle
        cx={loc.x}
        cy={loc.y}
        r={circleRadius}
        fill={fromFillColor}
        stroke="#161C21"
        border-width={1}
      >
        {isActive && (
          <animate
            attributeName="fill"
            values="#1F976B;#28624D;"
            dur={`${duration}s`}
            repeatCount="indefinite"
          />
        )}
      </circle>
      {isActive && (
        <circle
          cx={loc.x}
          cy={loc.y}
          r="6"
          fill="transparent"
          stroke-width="1"
          // stroke="#1F976B"
          // opacity={0.3}
          className="server-radius"
        >
          {isActive && (
            <>
              x
              <animate
                attributeName="r"
                values="5;7;5"
                dur={`${duration}s`}
                repeatCount="indefinite"
              />
              <animate
                attributeName="opacity"
                from={1}
                to={0.5}
                dur={`${duration}s`}
                repeatCount="indefinite"
              />
            </>
          )}
        </circle>
      )}
      {!isActive && (
        <line
          x1="8"
          y1="8"
          x2="16"
          y2="16"
          stroke={fromFillColor}
          stroke-linecap="round"
        />
      )}
    </svg>
  );

  return (
    <div className="server _mt10">
      <Animation />
      <div>
        <span style={{ color: fromFillColor }}>
          ShipThis Server <br />
          {isActive ? "online" : "offline"}
        </span>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const isMobile = useIsMobile(1024);
  const { isShowing } = useSidebarState();

  useLockBody(isShowing && isMobile, !isShowing || !isMobile, [
    isShowing,
    isMobile
  ]);

  const asideStyles = useSpring({
    left: isShowing ? 0 : -200,
    config: {
      tension: 250
    }
  });

  const hide = () => {
    if (isMobile) hideSidebar(); // Mobile only, sidebar is always showing on desktop
  };

  return (
    <>
      <Overlay onPress={hide} isOpen={isShowing} />
      <animated.aside className="admin-sidebar" style={asideStyles}>
        <Logo
          linkTo="/dashboard"
          className="admin-logo"
          size="sm"
          onClick={hide}
        />
        <nav className="admin-nav">
          <ul>
            <li className="admin-navItem">
              <NavLink to="/dashboard" className="admin-navLink" onClick={hide}>
                <div className="admin-navIconWrap">
                  <Dashboard width={20} className="admin-navIcon" />
                </div>
                Dashboard
              </NavLink>
            </li>
            <li className="admin-navItem">
              <NavLink
                to="/credentials"
                className="admin-navLink"
                onClick={hide}
              >
                <div className="admin-navIconWrap">
                  <FileKey width={18} className="admin-navIcon" />
                </div>
                Credentials
              </NavLink>
            </li>
            <li className="admin-navItem">
              <NavLink to="/games" className="admin-navLink" onClick={hide}>
                <div className="admin-navIconWrap">
                  <Gamepad width={18} className="admin-navIcon" />
                </div>
                Games
              </NavLink>
            </li>
          </ul>
        </nav>

        <LastCommit />
        <ServerActivity />
      </animated.aside>
    </>
  );
};

export { Sidebar };
