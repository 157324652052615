import classNames from "classnames";
import React from "react";

interface Props extends React.ComponentProps<"div"> {
  variant?: "grey" | "green" | "red" | "blue" | "orange" | string;
  darkMode?: boolean;
}

const STATE_COLORS = {
  PENDING: "orange",
  PROCESSING: "blue",
  FAILED: "red",
  COMPLETED: "green"
};

const Status = ({ variant = "grey", className, children, ...props }: Props) => (
  <div
    className={classNames("status", `status--${variant}`, className)}
    {...props}
  >
    {children}
  </div>
);

export { STATE_COLORS, Status };
