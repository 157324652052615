import React, { useState } from "react";
import {
  Credential,
  Section,
  TextInput,
  FormControl,
  Spinner,
  EmptyState
} from "@app/components";
import { Platform, Project } from "@app/types";
import { useProjectCredentials } from "@app/utils/query";
import { Link } from "react-router-dom";

interface Props {
  projectId: Project["id"];
}

const GameCredentials = ({ projectId }: Props) => {
  const { data, isLoading, isSuccess } = useProjectCredentials(
    projectId,
    Platform.IOS
  );

  if (isLoading) return <Spinner />;

  return (
    <>
      {isSuccess && data?.projectCredentials?.length === 0 && (
        <EmptyState message="No credentials found" className="_mb15">
          <div>
            Create some like{" "}
            <Link
              className="emptyState-action"
              to="/docs/tutorial/setting-up-shipthis#creating-a-shipthis-account"
            >
              this
            </Link>
          </div>
        </EmptyState>
      )}

      <Section>
        {data?.projectCredentials?.map(({ id, type, serialNumber }) => (
          <FormControl
            key={id}
            label="Mobile Provisioning Profile"
            icon={
              <div className="formItem-credentialIcon">
                <Credential type={type} size={16} />
              </div>
            }
          >
            <TextInput value={serialNumber} onChange={() => {}} isProtected />
          </FormControl>
        ))}
      </Section>
    </>
  );
};

export { GameCredentials };
