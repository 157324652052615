import React, { useState } from "react";
import {
  Box,
  FormControl,
  PhoneOs,
  Section,
  TextInput,
  Toggle
} from "@app/components";
import { Platform } from "@app/types";

const GameIdentity = ({ formik }) => {
  const [platform, setPlatform] = useState<Platform>(Platform.IOS);

  return (
    <Section>
      <div className="_mb10">
        <div className="gameOverview-header">
          <div className="_flex _flex-center">
            <Box width={18} height={18} className="gameOverview-headerIcon" />
            <div>Identity</div>
          </div>
          <Toggle
            value={platform}
            values={[Platform.IOS, Platform.ANDROID]}
            onChange={(value) => setPlatform(value)}
          />
        </div>
        <div className="font--sm">
          These identifiers uniquely identify your game within each platform.
        </div>
      </div>

      {platform === Platform.IOS && (
        <FormControl
          label="iOS Bundle Id"
          icon={
            <PhoneOs
              platform={Platform.IOS}
              size={16}
              className="gameOverview-osIcon"
            />
          }
        >
          <TextInput
            title="iOS Bundle Id"
            placeholder="com.company.appname"
            value={formik.values.details.iosBundleId}
            // isLoading={isDirty("details.iosBundleId")}
            onChange={(v) => formik.setFieldValue("details.iosBundleId", v)}
            onSubmit={formik.handleSubmit}
            isProtected
          />
        </FormControl>
      )}
      {platform === Platform.ANDROID && (
        <FormControl
          label="Android Package Name"
          icon={
            <PhoneOs platform={Platform.ANDROID} size={16} fill="#486f92" />
          }
        >
          <TextInput
            title="Android Package Name"
            placeholder="com.companyname.applicationname"
            value={formik.values.details.androidPackageName}
            // isLoading={isDirty("details.androidPackageName")}
            onChange={(v) =>
              formik.setFieldValue("details.androidPackageName", v)
            }
            onSubmit={formik.handleSubmit}
            isProtected
          />
        </FormControl>
      )}
    </Section>
  );
};

export { GameIdentity };
