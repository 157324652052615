import React from "react";
import classNames from "classnames";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "@react-spring/web";

interface Props extends React.ComponentProps<"div"> {
  children: React.ReactNode;
  type?: "error" | "success";
}

const StatusMessage = ({ children, type = "error", className }: Props) => {
  const [ref, { height }] = useMeasure();

  const [animatedValues] = useSpring(
    () => ({
      to: { height: height },
      config: {
        tension: 350
      }
    }),
    [height]
  );

  return (
    <animated.div
      style={animatedValues}
      className={classNames("statusMessage", className)}
    >
      <div
        ref={ref}
        className={classNames("statusMessage-text", {
          "statusMessage--success": type === "success",
          "statusMessage--error": type === "error"
        })}
      >
        {children}
      </div>
    </animated.div>
  );
};

export { StatusMessage };
