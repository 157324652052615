import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  EmptyState,
  Filter,
  ShortName,
  Spinner,
} from "@app/components";
import { useProjects } from "@app/utils/query";
import { Section, SectionHeader as Header } from "@app/components";

const MyRecentProjects = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const { data, isLoading, isSuccess } = useProjects({
    pageNumber: 0,
    pageSize: 100,
    orderBy: "createdAt",
    order,
  });

  const changeOrder = () => {
    setOrder(order === "asc" ? "desc" : "asc");
  };

  return (
    <Section variant="ghost">
      <Header>
        <div className="_flex _flex-center">
          <span className="_mr10">Recent Games</span>
          {isLoading && <Spinner />}
        </div>
        <Button variant="dark" size="sm" onClick={changeOrder}>
          <Filter className="_mr5" />
          Filter
        </Button>
      </Header>

      {isSuccess && data?.projects?.length === 0 && (
        <EmptyState message="No recent Games" className="_mb15">
          <div>
            Create one like{" "}
            <a
              href="/docs/wizard"
              target="_blank"
              className="emptyState-action"
            >
              this
            </a>
          </div>
        </EmptyState>
      )}

      <div className="list">
        {data?.projects?.map(({ id, name }) => (
          <Link
            key={id}
            className="list-item list--games-recent"
            to={`/games/${id}`}
          >
            <ShortName name={name} className="list-icon" />
            <div>{name}</div>
          </Link>
        ))}
      </div>

      {isSuccess && data?.projects?.length > 0 && (
        <footer className="list-footer">
          <Button size="sm" onClick={() => navigate("/games")}>
            All Games
          </Button>
        </footer>
      )}
    </Section>
  );
};

export { MyRecentProjects };
