import React from "react";
import classNames from "classnames";
import { Box } from "@app/components/Icons";

interface Props extends React.ComponentProps<"div"> {
  icon?: React.ReactNode;
  message: string;
}

const EmptyState = ({
  className,
  icon = <Box />,
  message,
  children
}: Props) => {
  return (
    <div className={classNames("emptyState", className)}>
      <div className="emptyState-icon">{icon}</div>
      <div className="emptyState-message">{message}</div>
      <div className="emptyState-action">{children}</div>
    </div>
  );
};

export { EmptyState };
