import React, { useRef } from "react";
import { useFormik } from "formik";

import { Project } from "@app/types";
import { useProject, useProjectMutation } from "@app/utils/query";
import { Spinner } from "@app/components";
import {
  GameEngine,
  GameIdentity,
  GameName,
  GameSetupProgress,
  GameVersioning
} from "./Sections";

const GameOverview = ({ projectId }: { projectId: Project["id"] }) => {
  const { data: project, isLoading } = useProject(projectId);
  const projectMutation = useProjectMutation(projectId);

  const initialDetails = useRef({
    semanticVersion: project?.details.semanticVersion || "0.0.1",
    buildNumber: project?.details.buildNumber || 1,
    ...project?.details
  }).current;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { name: project?.name, details: initialDetails },
    async onSubmit(values) {
      projectMutation.mutate(values);
    }
  });

  if (isLoading) return <Spinner />;

  return (
    <div className="gameOverview">
      <GameName formik={formik} />
      <div className="grid grid--2col">
        <GameIdentity formik={formik} />
        <GameSetupProgress projectId={projectId} />
      </div>
      <div className="grid grid--2col">
        <GameVersioning formik={formik} />
        <GameEngine formik={formik} />
      </div>
    </div>
  );
};

export { GameOverview };
