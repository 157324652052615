import React, { useEffect } from "react";

interface Bubbles {
  x: number;
  y: number;
  r: number;
  dx: number;
  dy: number;
  o: number;
  alive: boolean;
}

const canvasAnimation = () => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  canvas.width = document.documentElement.clientWidth;
  canvas.height = window.innerHeight;

  window.onresize = (event) => {
    canvas.width = document.documentElement.clientWidth;
    canvas.height = window.innerHeight;
  };

  let bubbles: Bubbles[] = [];

  const draw = (x: number, y: number, r: number, o: number) => {
    context.beginPath();
    context.globalAlpha = o;
    // context.filter = "blur(1px)";
    context.arc(x, y, r, 0, 2 * Math.PI);
    context.fillStyle = "#fff";
    context.fill();
    context.globalAlpha = 1;
    context.closePath();
  };

  const handleAddCircle = ({
    pageX: x,
    pageY: y
  }: {
    pageX: number;
    pageY: number;
  }) => {
    const newBubble = {
      x,
      y,
      r: 1,
      dx: Math.random() * 1 - 2,
      dy: -Math.random() * 1 - 2,
      o: 0.4,
      alive: true
    };
    bubbles = [newBubble, ...bubbles];
  };

  const handleDirection = (b: Bubbles) => {
    if (Math.random() < 0.1) {
      b.dx = Math.random() * 5 - 2.5;
      b.dy = -Math.random() * 2;
      if (b.dx > 0 && b.dx < 0.5) {
        b.o = 1;
        b.dy = b.dy - Math.random() * 5;
        // if (Math.random() < 0.1) {
        //   b.r = 2;
        // }
      } else {
        b.o = 0.4;
        b.r = 1;
      }
    }
  };

  const animate = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);

    if (Math.random() < 0.05) {
      handleAddCircle({
        pageX: Math.random() * canvas.width,
        pageY: Math.random() * canvas.height
      });
    }

    bubbles.map((b) => {
      handleDirection(b);
      if (b.y < -(b.r * 2)) b.alive = false;
      if (!b.alive) return;
      b.x += b.dx;
      b.y += b.dy;
      draw(b.x, b.y, b.r, b.o);
    });
    requestAnimationFrame(animate);
  };

  canvas.addEventListener("click", handleAddCircle);

  animate();
};

const Sparkles = () => {
  useEffect(() => {
    canvasAnimation();
  }, []);

  return <canvas id="canvas" />;
};

export { Sparkles };
