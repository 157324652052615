import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { cacheKeys, CLI_DOCS_BASE } from "@app/constants";

export async function fetchReadMe(filename: string): Promise<string> {
  try {
    const fullReadMeUrl = `${CLI_DOCS_BASE}${filename}`;
    const { data } = await axios.get(fullReadMeUrl);
    return data;
  } catch (error) {
    console.warn("fetchReadMe Error", error);
    throw error;
  }
}

export const useReadMe = (filename) => {
  return useQuery<string, AxiosError>({
    queryKey: cacheKeys.docs(filename),
    queryFn: () => fetchReadMe(filename),
  });
};
