import React from "react";
import { Link } from "react-router-dom";

import {
  Section,
  SectionHeader as Header,
  ShortName,
  Spinner,
  STATE_COLORS,
  Status,
  TimeAgo,
  EmptyState,
} from "@app/components";
import { Job } from "@app/types";
import { getVersionInfo } from "@app/utils/helpers";
import { useMyRecentJobs } from "@app/utils/query";
import { PhoneOs } from "@app/components/Icons";

const MyRecentJobs = () => {
  const { data, isLoading, isSuccess } = useMyRecentJobs();

  return (
    <Section variant="ghost">
      <Header>
        <div className="_flex _flex-center">
          Recent Jobs {isLoading && <Spinner />}
        </div>
      </Header>

      {isSuccess && data?.length === 0 && (
        <EmptyState message="No recent Jobs" className="_mb15">
          <div>
            Once you{" "}
            <a
              href="docs/reference/game/ship"
              target="_blank"
              className="emptyState-action"
            >
              ship
            </a>{" "}
            your first game
          </div>
        </EmptyState>
      )}

      <div className="list">
        {data?.map(({ id, status, type, project, updatedAt, details }: Job) => (
          <Link
            key={id}
            className="list-item list--jobs-recent"
            to={`/games/${project.id}/job/${id}`}
          >
            <ShortName name={project.name} className="list-icon" />
            <div className="recentJobs-nameWrap">
              <span className="recentJobs-name">
                <PhoneOs
                  platform={type}
                  size={16}
                  className="_mr5"
                  style={{ marginLeft: -2, top: 3, opacity: 0.8 }}
                />
                {project.name}
              </span>
              <span className="_o50 _flex _flex-center">
                {getVersionInfo(details)}
                <TimeAgo date={updatedAt} className="_ml5" />
              </span>
            </div>

            <Status variant={STATE_COLORS[status]}>{status}</Status>
          </Link>
        ))}
      </div>
    </Section>
  );
};

export { MyRecentJobs };
