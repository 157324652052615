import React from "react";
import { animated, useSpring } from "@react-spring/web";

interface Props<T> extends React.ComponentProps<"div"> {
  as?: T;
}

const Splash = ({ as: Tag = "div", children, ...props }: Props<typeof Tag>) => {
  const [animatedValues] = useSpring(
    () => ({
      from: { opacity: 0, translateY: -25 },
      to: { opacity: 1, translateY: 0 },
      config: {
        tension: 100,
        friction: 15
      }
    }),
    []
  );

  return (
    <animated.div style={animatedValues} className="splash">
      <Tag {...props}>{children}</Tag>
    </animated.div>
  );
};

export { Splash };
