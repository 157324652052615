import React from "react";
import { Link } from "react-router-dom";
import { Build as BuildType } from "@app/types";
import {
  Archer,
  Button,
  Chevron,
  Download,
  Git,
  TimeAgo
} from "@app/components";
import {
  getGitInfo,
  getPlatformName,
  getShortUUID,
  getVersionInfo
} from "@app/utils/helpers";
import { useDownloadBuild } from "@app/utils/hooks";

interface Props {
  build: BuildType;
}

const BuildHeader = ({ build }: { build: BuildType }) => {
  const { id, platform, projectId, createdAt, jobDetails } = build;
  const { downloadBuild } = useDownloadBuild();

  return (
    <Link
      className="build-header"
      to={`/games/${build.projectId}/job/${build.jobId}`}
    >
      <span className="build-id">
        <Chevron width={18} stroke="white" />
        <span>#{getShortUUID(id, 5)}</span>
      </span>
      <span>
        <span className="build-name">{getVersionInfo(jobDetails)}</span>
        <span className="build-type">{getPlatformName(platform)}</span>
      </span>
      {/* <Link
        className="build-job"
        to={`/games/${build.projectId}/job/${build.jobId}`}
      >
        JOB{" "}
        <Archer stroke="white" width={15} height={15} className="_ml5 _mr5" />
        <span>#{getShortUUID(build.jobId, 5)}</span>
      </Link> */}
      <span></span>
      <span className="build-git">
        <Git stroke="white" width={15} height={15} className="_mr5" />
        {getGitInfo(jobDetails)}
      </span>
      <TimeAgo date={createdAt} showIcon showAgo className="job-updated" />
      <Button
        size="sm"
        variant="dark-alt"
        className="build-downloadButton"
        onClick={() => downloadBuild({ buildId: build.id, projectId })}
      >
        <span className="_mr5">Download</span>
        <Download width={16} stroke="#95a0aa" />
      </Button>
    </Link>
  );
};

const Build = ({ build }: Props) => (
  <div className="build">
    <BuildHeader build={build} />
  </div>
);

export { Build };
