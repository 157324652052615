import React, { useState } from "react";
import { Platform } from "@app/types";
import {
  FormControl,
  PhoneOs,
  Button,
  Download,
  Spinner,
  StatusMessage,
  EmptyState,
} from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { useDownloadCredential } from "@app/utils/hooks";
import { Link } from "react-router-dom";

interface Props {
  credentials: any;
  platform: Platform;
  isLoading: boolean;
  isSuccess: boolean;
  missingMessage?;
}

const CredentialList = ({
  credentials,
  platform,
  isLoading,
  isSuccess,
  missingMessage,
}: Props) => {
  const [downloadError, setDownloadError] = useState<undefined | string>();
  const { downloadCredential, isLoading: isDownloading } =
    useDownloadCredential();

  const handleDownloadPress = async (credentialId: string) => {
    try {
      await downloadCredential({ credentialId });
    } catch (error) {
      console.warn(error);
      setDownloadError(getErrorMessage(error));
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      {downloadError && (
        <StatusMessage type="success" className="_mb10">
          {downloadError}
        </StatusMessage>
      )}

      {credentials?.map(({ id, type, isActive, serialNumber }) => (
        <FormControl
          label={
            {
              CERTIFICATE: "Distribution Certificate",
              KEY: "Key",
            }[type]
          }
          className="userCredential"
          icon={
            <PhoneOs
              platform={platform}
              size={16}
              className="userCredential-os"
            />
          }
          altIcon={
            isActive && <div className="userCredential-isActive">Active</div>
          }
        >
          <div className="_flex _flex-center">
            <div className="userCredential-text">{serialNumber}</div>
            <Button
              size="sm"
              variant="dark-alt"
              className="_ml5"
              onClick={() => handleDownloadPress(id)}
              isLoading={isDownloading}
              centeredLoader
            >
              <span className="_mr5">Download</span>
              <Download width={16} stroke="#95a0aa" />
            </Button>
          </div>
        </FormControl>
      ))}

      {isSuccess && credentials?.length === 0 && (
        <EmptyState message="No credentials found" className="_mb15">
          <div>{missingMessage}</div>
        </EmptyState>
      )}
    </>
  );
};

export { CredentialList };
