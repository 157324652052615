import React from "react";
import { LogoSymbol, LogoSymbol2, LogoSymbol3 } from "@app/components/Icons";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";

interface Props extends React.ComponentProps<"div"> {
  linkTo?: string;
  withText?: boolean;
  withBeta?: boolean;
  size?: "sm" | "md";
}

const Logo = ({
  linkTo = "/",
  withText,
  withBeta,
  className,
  size,
  ...props
}: Props) => (
  <div
    className={classNames(
      "logo",
      className,
      { "logo--withText": withText },
      { "logo--withBeta": withBeta },
      { "logo--size-sm": size === "sm" }
    )}
    {...props}
  >
    <NavLink to={linkTo} className="_flex">
      <LogoSymbol2 />
      <span className="logo-text">ShipThis</span>
    </NavLink>
    <NavLink to="/beta" className="logo-beta">
      BETA
    </NavLink>
  </div>
);

export { Logo };
