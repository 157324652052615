import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Self } from "@app/types";
import { API_URL, cacheKeys } from "@app/constants";
import { castObjectDates } from "@app/utils/dates";

import { getAuthHeaders, getCurrentJWT } from "./useIsSignedIn";

export async function fetchSelf(): Promise<Self> {
  try {
    if (!getCurrentJWT()) return null; // BugFixed: remove data after logout
    const headers = getAuthHeaders();
    const response = await axios.get(`${API_URL}/me`, { headers });
    return castObjectDates<Self>(response.data);
  } catch (error) {
    console.warn("fetchSelf Error", error);
    throw error;
  }
}

export const useSelf = (): UseQueryResult<Self> => {
  return useQuery<Self, AxiosError>({
    queryKey: cacheKeys.self,
    queryFn: fetchSelf,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
