import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const Filter = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      {...props}
    >
      <path d="M2.5 4h12M5.167 8h6.666m-4 4h1.334" />
    </svg>
  );
};
