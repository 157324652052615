import React from "react";
import { useSelf } from "@app/utils/query";

import { Header } from "../_Shared";
import { MyRecentJobs } from "./MyRecentJobs";
import { MyRecentProjects } from "./MyRecentProjects";

const Dashboard = () => {
  const { isLoading, data: self } = useSelf();

  return (
    <>
      <main className="admin-main">
        <Header title="Dashboard" />
        <div className="grid grid--2col admin__grid">
          <MyRecentProjects />
          <MyRecentJobs />
        </div>
      </main>
    </>
  );
};

export { Dashboard };
