import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "@react-spring/web";

import { useAcceptTermsMutation } from "@app/utils/query";
import { Button, Logo } from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { Footer, Splash } from "../_Shared";

export const Agreement = () => {
  const [isChecked, setIsChecked] = useState(false);

  const acceptTermsMutation = useAcceptTermsMutation();
  const [error, setError] = useState<undefined | string>();
  const navigate = useNavigate();
  const [ref, { height }] = useMeasure();

  const [animatedValues, api] = useSpring(
    () => ({
      to: { height: height },
      config: {
        tension: 350
      }
    }),
    [height]
  );

  const handleSubmit = () => {
    if (!isChecked) {
      setError(
        "You must agree to the terms and conditions and privacy policy to continue."
      );
      return;
    }

    try {
      acceptTermsMutation.mutate();
      setError("");
      navigate("/dashboard");
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  return (
    <Splash as="main">
      <form
        className="auth"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="_flex _flex-center _flex-spaceBetween">
          <Logo size="sm" />
          <h1 className="auth_h1">Agreements</h1>
        </div>
        {error && (
          <animated.div style={animatedValues} className="auth_error_wrap">
            <div ref={ref} className="auth_error">
              {error}
            </div>
          </animated.div>
        )}

        <div className="_mb10 _mt10">
          <label className="_flex _flex-center _flex-column">
            <input
              type="checkbox"
              className="auth-checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />

            <span className="_mt10">
              I agree to the{" "}
              <a href="/terms" target="_blank">
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
              .
            </span>
          </label>
        </div>

        <Button
          size="md"
          onClick={handleSubmit}
          centeredLoader
          isLoading={acceptTermsMutation.isPending}
        >
          Continue
        </Button>
        <input type="submit" hidden />
      </form>
      <Footer />
    </Splash>
  );
};
